body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ReactCollapse--collapse {
  transition: height 300ms;
}

@font-face {
	font-family: "GasoekOne";
	src: url(./public/assets/fonts/GasoekOne-Regular.ttf);
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 900;
	font-display: swap;
	src: local('Pretendard Black'), url('./public/assets/fonts/woff2/Pretendard-Black.woff2') format('woff2'), url('./public/assets/fonts/woff/Pretendard-Black.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 800;
	font-display: swap;
	src: local('Pretendard ExtraBold'), url('./public/assets/fonts/woff2/Pretendard-ExtraBold.woff2') format('woff2'), url('./public/assets/fonts/woff/Pretendard-ExtraBold.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 700;
	font-display: swap;
	src: local('Pretendard Bold'), url('./public/assets/fonts/woff2/Pretendard-Bold.woff2') format('woff2'), url('./public/assets/fonts/woff/Pretendard-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 600;
	font-display: swap;
	src: local('Pretendard SemiBold'), url('./public/assets/fonts/woff2/Pretendard-SemiBold.woff2') format('woff2'), url('./public/assets/fonts/woff/Pretendard-SemiBold.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 500;
	font-display: swap;
	src: local('Pretendard Medium'), url('./public/assets/fonts/woff2/Pretendard-Medium.woff2') format('woff2'), url('./public/assets/fonts/woff/Pretendard-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 400;
	font-display: swap;
	src: local('Pretendard Regular'), url('./public/assets/fonts/woff2/Pretendard-Regular.woff2') format('woff2'), url('./public/assets/fonts/woff/Pretendard-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 300;
	font-display: swap;
	src: local('Pretendard Light'), url('./public/assets/fonts/woff2/Pretendard-Light.woff2') format('woff2'), url('./public/assets/fonts/woff/Pretendard-Light.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 200;
	font-display: swap;
	src: local('Pretendard ExtraLight'), url('./public/assets/fonts/woff2/Pretendard-ExtraLight.woff2') format('woff2'), url('./public/assets/fonts/woff/Pretendard-ExtraLight.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 100;
	font-display: swap;
	src: local('Pretendard Thin'), url('./public/assets/fonts/woff2/Pretendard-Thin.woff2') format('woff2'), url('./public/assets/fonts/woff/Pretendard-Thin.woff') format('woff');
}

:root {
	--font-gasoekOne: "GasoekOne";
	--font-pretendard: "Pretendard";
}